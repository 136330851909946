import React from 'react'
import videoOne from '../images/video1.gif'
import videoTwo from '../images/video2.gif'
function VideoComponent() {
    return (
        <div className='container'>
            <img src={videoOne} alt='' style={{
                width: '100%'
            }} />

            <p className='section'></p>
            <img src={videoTwo} alt='' style={{
                width: '100%'
            }} />
        </div>
    )
}

export default VideoComponent
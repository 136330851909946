import React from 'react'
import {
    AiFillInstagram,
    AiFillFacebook,
    AiFillLinkedin,
} from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'
import { BiPhoneCall } from 'react-icons/bi'
import { Link } from 'react-router-dom'


const companyProfile = <>
    <p style={{ textAlign: 'justify', fontSize: '14px' }}>Our company specializes in developing and producing an automatic food waste management system designed for households. Our system is a smart solution that helps homeowners reduce food waste and contribute to a more sustainable future.</p>
    <p style={{ textAlign: 'justify', fontSize: '14px' }}>Our product is compact and easy to install, making it perfect for small homes and apartments. It uses innovative technology to process food waste quickly and efficiently, minimizing odors and reducing the need for traditional waste disposal methods.</p>
</>




const contactus = <ul className='footer-addsr' style={{ listStyle: 'none', fontSize: '14px', lineHeight: '20px', padding: 0, textAlign: 'center' }}>
    <li>Assistance India, XVII/106, </li>
    <li>Kadangallor Panchyath,</li>
    <li>Muppathdam, </li>
    <li>Eranakulam,  Kerala, India - 683 110</li>
    <li>&nbsp;</li>
    <li style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>+91-9847-037-111 <BiPhoneCall style={{ fontSize: '20px' }} /></li>
    <li style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}> contact@assistanceindia.com <FiMail style={{ fontSize: '20px' }} /></li>
    <li >
        <ul className='d-flex gap-4 m-4' style={{ listStyle: 'none', padding: 0, justifyContent: 'center', }}>
            <li style={{ fontSize: '30px' }}><Link to="https://www.facebook.com/AssistanceIndia"><AiFillFacebook /></Link></li>
            <li style={{ fontSize: '30px' }}><Link to="https://www.instagram.com/AssistanceIndia"><AiFillInstagram /></Link></li>
            {/* <li style={{ fontSize: '30px' }}><AiFillTwitterSquare /></li> */}
            <li style={{ fontSize: '30px' }}><Link to="https://www.linkedin.com/company/assistanceindia"><AiFillLinkedin /></Link></li>
            {/* <li style={{ fontSize: '30px' }}><AiFillYoutube /></li> */}
        </ul>
    </li>
</ul>

function Footer() {
    return (
        <footer className='flex-column'>
            <div className='container d-flex flex-wrap justify-content-between'>
                <div className='footer-content'>
                    <h4 className='text-center footertexthead'><strong>Assistance India</strong></h4>
                    {companyProfile}
                </div>

                <div className='footer-content footertexthead'>
                    <h4 className='footertexthead'><strong className='mb-2'>Contact Us</strong></h4>
                    {contactus}
                </div>
            </div>
            <div className='text-center'>&copy; {(new Date().getFullYear())} Assistance India <br/> <small>Powered by <Link to="https://ahydratech.com/">Ahydra &reg;</Link></small></div>
        </footer>
    )
}

export default Footer
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { ImLeaf } from 'react-icons/im'
import greemEarthImage from '../images/greenearth.jpg'
import { Link } from 'react-router-dom'
import brochure from '../pdf/brochure.pdf'

const text = <ul style={{ listStyle: 'none', padding: 0 }}>
    <li style={{ margin: '20px' }}><ImLeaf className='text-success' /> Minimum space, power and labor requirement.</li>
    <li style={{ margin: '20px' }}><ImLeaf className='text-success' /> Easy installation and maintenance.</li>
    <li style={{ margin: '20px' }}><ImLeaf className='text-success' /> Fully automatic</li>
    <li style={{ margin: '20px' }}><ImLeaf className='text-success' /> Processing time with less than 24 hours.</li>
    <li style={{ margin: '20px' }}><ImLeaf className='text-success' /> No emissions, smoke or odour.</li>
</ul>


function Features() {

    return (
        <div className='container d-flex flex-wrap justify-content-around align-items-center featuresContainer' style={{ fontFamily: 'Raleway', fontSize: '16px' }}>

            <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
            >
                <div className=''><img src={greemEarthImage} alt='' style={{ width: '350px' }} /></div>
            </AnimationOnScroll>
            <div className=''>
                <AnimationOnScroll
                    animateIn="animate__fadeInRight"
                    animateOnce={true}
                >
                    <h3><strong>Unique Features</strong></h3>
                    <div>{text}</div>
                    <div className='d-flex justify-content-center'><Link className='btn btn-success' to={brochure} target='_target' download>Download E-Brochure</Link></div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default Features
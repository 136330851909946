import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'


function ServiceComponent() {
    const treePlanetText = "Tree planet is a hypothetical world where trees dominate the landscape and play a vital role in sustaining life. It is our responsibility to keep world tidy and clean."
    const aroundCleanText = 'Keeping things clean around you is important for both your physical and mental health. When you keep your surroundings clean, you reduce the risk of infections and illnesses caused by germs and bacteria. '
    const greenAreas = 'Keeping house  as clean is crucial for maintaining a healthy and sustainable environment. Green areas provide a multitude of benefits to the ecosystem, including oxygen production, carbon sequestration, and biodiversity conservation.'

    const servicesStyle = {
        backgroundColor: '#00af46',
        padding: '20px',
        color: 'white'
    }


    const list = [
        "Compact design",
        "Automated operation",
        "Energy-efficient",
        "No foul odor",
        "Versatile use",
        "fast composting time",
        "Self-sustaining",
        "Low maintenance",
        "Modular design",
        "User-friendly",
        "High efficiency",
        "Microbial inoculants",
        "Compact design",
        "Automated operation",
        "Energy-efficient",
        "No foul odor",
        "Versatile use",
        "fast composting time",
        "Self-sustaining",
        "Low maintenance",
        "Modular design",
        "User-friendly",
        "High efficiency",
        "Microbial inoculants",
        "Compact design",
        "Automated operation",
        "Energy-efficient",
        "No foul odor",
        "Versatile use",
        "fast composting time",
        "Self-sustaining",
        "Low maintenance",
        "Modular design",
        "User-friendly",
        "High efficiency",
        "Microbial inoculants",
        "Compact design",
        "Automated operation",
        "Energy-efficient",
        "No foul odor",
        "Versatile use",
        "fast composting time",
        "Self-sustaining",
        "Low maintenance",
        "Modular design",
        "User-friendly",
        "High efficiency",
        "Microbial inoculants"
    ]

    return (
        <div style={servicesStyle}>
            <div className='container serviceContainer' style={{ fontFamily: 'Raleway', fontSize: '16px' }}>

                <marquee scrollamount="10">
                    <ul className='d-flex gap-4'>
                        {list.map((res, i) =>
                            (<li key={i}>{res}</li>)
                        )}
                    </ul>
                </marquee>
            </div>
        </div>
    )
}

export default ServiceComponent
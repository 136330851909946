import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import Topbar from './Components/Topbar'
import Home from './Pages/Home'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </>
  )

}

export default App
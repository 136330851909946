import React from 'react'
import AboutUsComponent from '../Components/AboutUsComponent'
import Banner from '../Components/Banner'
import Features from '../Components/Features'
import Footer from '../Components/Footer'
import ServiceAndSolutions from '../Components/ServiceAndSolutions'
import ServiceComponent from '../Components/ServiceComponent'
import '../css/Home.css'
import "animate.css/animate.min.css";
import { Button } from 'react-bootstrap'
import { useRef } from 'react'
import '../css/Topbar.css'
import { GiHamburgerMenu } from 'react-icons/gi'
import MiniSidebar from '../Components/MiniSidebar'
import { AiFillCloseCircle, AiFillMail } from 'react-icons/ai'
import { IoIosCall } from 'react-icons/io'
import Logo from '../images/AssistanceIndiaLogo.png'
import { useState } from 'react'
import VideoComponent from '../Components/VideoComponent'

function Home() {
    const homeRef = useRef(null);
    const serviceRef = useRef(null);
    const aboutUsRef = useRef(null);
    const productRef = useRef(null);

    const serviceAction = () => {
        serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const aboutAction = () => {
        aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const productAction = () => {
        productRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const homeAction = () => {
        homeRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const [toggleMiniSidebar, setToggleMiniSidebar] = useState(false)

    const showMiniSidebar = () => {
        if (toggleMiniSidebar) {
            return <div className='mini-sidebar'>
                <AiFillCloseCircle
                    onClick={() => setToggleMiniSidebar(!toggleMiniSidebar)}
                    style={{ zIndex: 99, fontSize: '25px', float: 'right' }} />
                <MiniSidebar />
            </div>
        } else {
        }
    }

    const pa = {
        margin: '10px 10px 10px 40px'
    }

    const logoStyles = {
        marginTop: '10px',
        width: "200px"
    }

    return (
        <>
            <section ref={homeRef} >
                <div className='nav-menu-container' style={{ position: 'sticky' }}>
                    <div className='container d-flex justify-content-end align-items-center gap-2'>
                        <div className='contactTopStyle'>
                            <div style={pa}>
                                <IoIosCall /> +91-9847-037-111
                                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                <AiFillMail /> contact@assistanceindia.com
                            </div>
                        </div>
                    </div>
                    <div className='container nav-menu-inner-container'>
                        <div>
                            <h2>
                                <img src={Logo} alt="" style={logoStyles} />
                            </h2>
                        </div>
                        <div>
                            <div className='nav-menu-toggle-container'>
                                <GiHamburgerMenu
                                    style={{ zIndex: 98 }}
                                    onClick={() => setToggleMiniSidebar(!toggleMiniSidebar)} />
                            </div>
                            <ul className='nav-menu'>
                                <li onClick={homeAction}>Home</li>
                                <li onClick={serviceAction}>Services</li>
                                <li onClick={productAction}>Product & Features</li>
                                <li onClick={aboutAction}>About us</li>
                                <li>Contact</li>
                                {/* <li><Button variant='success'>Request Demo</Button></li> */}
                            </ul>
                            <div>
                                {showMiniSidebar()}
                            </div>
                        </div>
                    </div>
                </div>
                <Banner />

                <section ref={serviceRef}>
                    <p className='section'></p>
                    <ServiceAndSolutions />
                    <p className='section'></p>
                    <p className='section'></p>
                    <ServiceComponent />
                </section>

                <p className='section'></p>
                <p className='section'></p>
                <section ref={serviceRef}>
                    <VideoComponent />
                </section>

                <section ref={productRef}>
                    <p className='section'></p>
                    <p className='section'></p>
                    <Features />
                </section>

                <section ref={aboutUsRef}>
                    <p className='section'></p>
                    <p className='section'></p>
                    <AboutUsComponent />
                </section>
                <p className='section'></p>
                <p className='section'></p>
                {/* <OurTechnology /> */}
                {/* <p className='section'></p> */}



                <Footer />
            </section>
        </>
    )
}

export default Home
import React from 'react'
import { Button } from 'react-bootstrap'

function MiniSidebar() {
    return (
        <div className='mini-sidebar-container'>
            <h3>Assiance India</h3>
            <ul className='minisidebar-menuitems'>
                <li className='mini-sidebar-li'>About Us</li>
                <li className='mini-sidebar-li'>Product</li>
                <li className='mini-sidebar-li'>FAQ</li>
                <li className='mini-sidebar-li'>Clients</li>
                <li className='mini-sidebar-li'>Contact</li>
                <li className='mini-sidebar-li'>Blog</li>
                <li className='mini-sidebar-li'><Button variant='success'>Request Demo</Button></li>
            </ul>
        </div>
    )
}

export default MiniSidebar
import React from 'react'
import pdtImg from '../images/RobobinB-1.png'
function Banner() {

    const bannerTitleText = {
        fontFamily: 'Raleway',
        fontWeight: '900',
        fontSize: '40px',
        color: '#fff',
        textShadow: '3px 1px 8px rgba(0,0,0,0.69)'
    }
    const imagestyle = {
        width: '100%',
        borderRadius: '10px'
    }

    return (
        <div className='banner-container bannerContainerMainwithbg'>
            <div className='container banner-container'>
                <div className='w-100 d-flex flex-column align-items-start justify-content-center p-2'>
                    <h3><strong style={bannerTitleText} >INDOOR WASTE MANAGING SYSTEM</strong></h3>
                    <p className='text-white' style={{textAlign:'justify'}}>
                        A compact and robust food waste converter for managing food waste in facilities like restaurants,  apartments, hostels, canteens, malls, catering, etc.
                    </p>
                    {/* <Button variant='success'>View Details</Button> */}
                </div>
                <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                    <img
                        className='pdtImg'
                        src={pdtImg}
                        // src='https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_442/https://www.solwearth.com/wp-content/uploads/2020/06/automatic-waste-convertor.png'
                        alt=''
                        style={imagestyle}
                    />
                    <div className='text-white'>Automatic Food waste converter</div>
                </div>
            </div>
        </div>
    )
}

export default Banner
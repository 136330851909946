import React from 'react'
import '../css/services.css'
import { RiRecycleFill } from 'react-icons/ri'
import { MdOutlineRecycling } from 'react-icons/md'
import { FaLeaf, FaHandHoldingWater } from 'react-icons/fa'
import { FcGlobe } from 'react-icons/fc'
import { GiWaterRecycling } from 'react-icons/gi'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function ServiceAndSolutions() {
  return (
    <div className='container mt-4'>
      <div id="pgc-14-3-0" className="panel-grid-cell">
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOnce={true}
        >
          <div id="panel-14-3-0-0" className="so-panel widget widget_orion_heading_w panel-first-child" data-index="9">
            <div className="orion default panel-widget-style panel-widget-style-for-14-3-0-0">
              <div className="so-widget-orion_heading_w so-widget-orion_heading_w-base">
                <div className="row orion-heading" style={{ marginBottom: "0px" }}>
                  <div className="col-md-12  separator-style-1 text-center style-h2 style-text-default">
                    <h2 className="text-default">
                      Services &amp; <span className="primary-color">Solutions</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="panel-14-3-0-1" className="so-panel widget widget_sow-editor" data-index="10">
            <div className="orion remove-padding-mobile default panel-widget-style panel-widget-style-for-14-3-0-1">
              <div className="so-widget-sow-editor so-widget-sow-editor-base">
                <div className="siteorigin-widget-tinymce textwidget">
                  <p className="lead" style={{ textAlign: "center" }}>
                    <em>We offer comprehensive recycling,&nbsp; environmental -specific&nbsp;waste management, quality&nbsp;control &amp; sustainability solutions for businesses and corporations.</em></p>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>

        <div id="panel-14-3-0-2" className="so-panel widget widget_orion_icon_text_w" data-index="11">
          <div className="orion mobile-text-center default panel-widget-style panel-widget-style-for-14-3-0-2">
            <div className="so-widget-orion_icon_text_w so-widget-orion_icon_text_w-base ">
              <div className="row icon-box-items-wrap grid icon-top service-grid">
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <RiRecycleFill />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">Waste Collection</h5>
                    </div>
                    <span className="description">
                    The main problem with food waste is disposal, but with this device, you can easily dispose of the food waste generated. Its waste collection feature can help manage and reduce food waste, promoting more sustainable and environmentally-friendly practices.
                    </span>
                  </AnimationOnScroll>
                </div>
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <MdOutlineRecycling />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">Recycling Services</h5>
                    </div>
                    <span className="description">By recycling food waste into high-quality fertilizers, this device provides an eco-friendly and sustainable solution for waste management. This process reduces the amount of food waste going into landfills and creates a valuable resource for agriculture and gardening.</span>
                  </AnimationOnScroll>
                </div>
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <FaLeaf />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">Environmental</h5>
                    </div>
                    <span className="description">This device is environmentally friendly because it can help reduce waste and promote sustainable practices. Its waste collection and recycling features can minimize the environmental impact of food waste and contribute to a cleaner and healthier planet.</span>
                  </AnimationOnScroll>
                </div>
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div href="#" className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <FcGlobe />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">Quality Audits</h5>
                    </div>
                    <span className="description">Environmental quality audits help us understand the impact of our actions on the planet. Let's use this knowledge to make more informed decisions and build a better future.</span>
                  </AnimationOnScroll>
                </div>
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <GiWaterRecycling />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">BIO-Fuel Production</h5>
                    </div>
                    <span className="description">By converting food waste into biofuel, this device provides an eco-friendly solution for waste management and energy production to the households.</span>
                  </AnimationOnScroll>
                </div>
                <div className="icon-box-wrap clearfix col-lg-4 col-md-4 col-sm-6 " style={{ marginBottom: "36px" }}>
                  <div className="icon-wrap relative  simple">
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce={true}
                    >
                      <span className="sow-icon-environmental" data-sow-icon="" style={{ color: "#22aa86" }}>
                        <FaHandHoldingWater />
                      </span>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div>
                      <h5 className="item-title">Sustainability</h5>
                    </div>
                    <span className="description">Sustainability is not a choice, it's the only way forward. Let's embrace a more responsible way of living and create a better world for ourselves and future generations.</span>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="panel-14-3-0-3" className="so-panel widget widget_orion_button_w panel-last-child" data-index="12">
          <div className="orion absolute-bottom absolute-bottom-center default panel-widget-style panel-widget-style-for-14-3-0-3">
            <div className="so-widget-orion_button_w so-widget-orion_button_w-base">

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceAndSolutions